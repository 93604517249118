.payslip-item[data-v-7c67b54a] {
  background: #fff;
  border-radius: 4px;
  margin-bottom: 12px;
  box-shadow: 0px 0.02rem 0.12rem 0px rgba(48, 49, 51, 0.2);
  padding: 0.08rem 0.12rem;
  border-bottom: 1px solid #ededed;
  font-size: 0.14rem;
  line-height: 0.3rem;
}
.payslip-item .payslip-tit[data-v-7c67b54a] {
    width: 100%;
    display: flex;
    justify-content: space-between;
    color: #000;
    padding-top: 0.08rem;
}
.payslip-item .info[data-v-7c67b54a] {
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-size: 0.13rem;
}
.payslip-item .time[data-v-7c67b54a] {
    color: rgba(0, 0, 0, 0.6);
    border-bottom: 1px solid #ededed;
}
@media screen and (min-width: 768px) {
.payslip-item[data-v-7c67b54a] {
    padding: 8px 12px;
    font-size: 14px;
    line-height: 30px;
}
.payslip-item .payslip-tit[data-v-7c67b54a] {
      padding-top: 8px;
}
.payslip-item .info[data-v-7c67b54a] {
      font-size: 13px;
}
}
