@charset "UTF-8";
.payslip-list-content[data-v-551281e2] {
  background: #fff;
  width: 100vw;
  height: 100vh;
  overflow: auto;
  padding-bottom: 0;
  /* 兜底方案 */
  padding-bottom: constant(safe-area-inset-bottom);
  /* 兼容 iOS < 11.2 */
  padding-bottom: env(safe-area-inset-bottom);
  /* 兼容 iOS >= 11.2 */
}
.payslip-list-content .payslip-total[data-v-551281e2] {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: #fff;
}
.payslip-list-content .payslip-total span[data-v-551281e2] {
      font-size: 0.2rem;
      line-height: 0.32rem;
      color: rgba(0, 0, 0, 0.7);
}
.payslip-list-content .payslip-total p[data-v-551281e2] {
      font-size: 0.12rem;
      line-height: 0.25rem;
      color: #aaa;
      padding-bottom: 0.04rem;
}
.payslip-list-content .payslip-list[data-v-551281e2] {
    padding: 0.08rem 0.12rem 0;
    overflow: hidden;
}
.payslip-list-content .payslip-list .finished-text[data-v-551281e2] {
      font-size: 0.14rem;
      line-height: 0.5rem;
      text-align: center;
      color: #aaa;
}
@media screen and (min-width: 768px) {
.payslip-list-content .payslip-list[data-v-551281e2] {
    padding: 10px 15px 0;
}
.payslip-list-content .payslip-list .finished-text[data-v-551281e2] {
      font-size: 14px;
      line-height: 50px;
}
}
