.year-month-con[data-v-4b4904b6] {
  width: 100%;
  background: #eee;
  padding: 0.05rem 0.15rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.year-month-con .year-month-select[data-v-4b4904b6] {
    width: 48%;
}
@media screen and (min-width: 768px) {
.year-month-con[data-v-4b4904b6] {
    padding: 10px 20px;
}
}
